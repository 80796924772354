<template>
    <v-sheet
        :width="!$vuetify.breakpoint.mobile? 220:''"
    >
        <v-sheet
            class="striped pl-4"
            :class="!$vuetify.breakpoint.mobile? 'pt-9':'pt-6'"
            :height="!$vuetify.breakpoint.mobile? '120':'100'"
        >
            <p
                class="mb-0 grey--text"
                :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-subtitle-2'"
            >
                {{$store.state.language == 'kr'? 'Business' : '사업소개'}}
            </p>
            <p
                class="mb-2 font-weight-bold primary--text"
                :class="!$vuetify.breakpoint.mobile? 'text-h5':'text-h6'"
            >
                {{$store.state.language == 'en'? 'Business' : '사업소개'}}
            </p>
            <v-divider class="mr-4"></v-divider>
        </v-sheet>
        <v-list
            class="mt-0 pt-0"
            :dense="$vuetify.breakpoint.mobile"
        >
            <v-list-item
                v-for="(item, index) in menu"
                :key="index"
                class="pl-4"
                :class="!$vuetify.breakpoint.mobile? 'text-h6':''"
                style="cursor:pointer; border-bottom:1px solid #ddd;"
                :style="item.url == $route.path? 'background:#2d76a7;':''"
                @click="link(item.url)"
            >
                <v-list-item-title>
                    <font :color="item.url == $route.path? 'white':'black'">
                        {{$store.state.language == 'kr'? item.title_kr : item.title_en}}
                    </font>
                </v-list-item-title>
                <v-list-item-icon>
                    <v-icon :color="item.url == $route.path? 'white':'#2d76a7'">mdi-chevron-right</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        menu: [
            {
                title_kr: "환경측정기 제조",
                title_en: "Measuring Inst.",
                url: "/business/manufacture"
            },
            {
                title_kr: "R F 단말기",
                title_en: "RF Terminal",
                url: "/business/rf"
            },
            {
                title_kr: "엔진오일 첨가제",
                title_en: "Engine Oil Add.",
                url: "/business/additive"
            },
        ]
    }),

    methods: {
        link(url){
            this.$router.push(url)
        }
    }
}
</script>
<style scoped>
.striped {
    background: repeating-linear-gradient(
        45deg,
        white,
        white 2px,
        #eeeeee 2px,
        #eeeeee 4px
    );
}
</style>