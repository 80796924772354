<template>
    <div>
        <!-- 배너 -->
        <Banner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    {{$store.state.language == 'kr'? '가스 분석기' : 'Gas Analyzer'}}
                </p>
                <v-divider></v-divider>

                <div
                    class="d-flex py-4"
                >
                    <TipTapReader 
                        v-if="options.content"
                        style="min-height:300px;"
                        class="tiptap_style"
                        :options="options"
                        :key="componentKey"
                    />
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'
import TipTapReader from "@/components/tiptap/Reader"
import { mapState } from 'vuex'

export default {
    components: {
        Banner,
        SideMenu,
        TipTapReader
    },

    data: () => ({
        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        componentKey: 0
    }),

    computed: {
        ...mapState(['language'])
    },

    created() {
        this.$watch('language', this.handleLanguageChange)
    },

    mounted(){
        this.load()
    },

    methods: {
        handleLanguageChange(newLanguage, oldLanguage) {
            this.$http.post("/api/admin/product/select/specific", {
                params: {
                    page: "gas",
                    device: this.$vuetify.breakpoint.mobile? 'mobile': 'pc',
                    language: newLanguage
                }
            }).then((res) => {
                this.options.content = res.data[0].content
                this.componentKey++
            })
        },

        load(){
            this.$http.post("/api/admin/product/select/specific", {
                params: {
                    page: "gas",
                    device: this.$vuetify.breakpoint.mobile? 'mobile': 'pc',
                    language: this.$store.state.language
                }
            }).then((res) => {
                this.options.content = res.data[0].content
            })
        }
    }
}
</script>