<template>
    <div>
        <!-- 배너 -->
        <Banner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    {{$store.state.language == 'kr'? '환경측정기 제조' : 'Manufacture of environmental measuring instruments'}}
                </p>
                <v-divider></v-divider>

                <!-- <v-sheet
                    class="text-center"
                    :class="!$vuetify.breakpoint.mobile? 'pt-10 px-12':'pt-6'"
                >
                    <p
                        class="font-weight-bold secondary--text"
                        :class="!$vuetify.breakpoint.mobile? 'text-h4':'text-h5'"
                    >
                        {{$store.state.language == 'kr'? '자동차 환경 측정 기기' : 'automotive environment measuring instrument'}}
                    </p>
                    <p
                        v-if="$store.state.language=='kr'"
                        class="font-weight-medium grey--text text--darken-1"
                        :class="!$vuetify.breakpoint.mobile? '':'text-body-2 px-2'"
                    >
                        지구환경에 악영향을 미치는 배출가스 및 매연을 측정하는<br v-if="!$vuetify.breakpoint.mobile" />
                        시험장비로 배기가스 규제에 솔루션 지원
                    </p>
                     <p
                        v-if="$store.state.language=='en'"
                        class="font-weight-medium grey--text text--darken-1"
                        :class="!$vuetify.breakpoint.mobile? '':'text-body-2 px-2'"
                    >
                        Measurement of emissions and smoke that adversely affect the global environment<br v-if="!$vuetify.breakpoint.mobile" />
                        Test equipment supports solutions for emissions regulation
                    </p>
                    <div class="d-flex">
                        <v-sheet
                            width="50%"
                            class="secondary lighten-1 white--text pt-7 pb-8 px-4"
                            :class="!$vuetify.breakpoint.mobile? 'text-h4':'text-h5'"
                        >
                            <v-icon class="d-block" color="grey lighten-3" size="40">mdi-format-quote-open-outline</v-icon>
                            <p v-if="$store.state.language=='kr'" class="font-weight-bold my-3">광투과식 매연 측정기</p>
                            <p v-if="$store.state.language=='en'" class="font-weight-bold my-3">Diesel Smoke Meter</p>
                            <v-icon class="d-block" color="grey lighten-3" size="40">mdi-format-quote-close-outline</v-icon>
                        </v-sheet>
                        <v-sheet
                            width="50%"
                            class="secondary lighten-2 white--text pt-7 pb-8 px-4"
                            :class="!$vuetify.breakpoint.mobile? 'text-h4':'text-h5'"
                        >
                            <v-icon class="d-block" color="grey lighten-3" size="40">mdi-format-quote-open-outline</v-icon>
                            <p v-if="$store.state.language=='kr'" class="font-weight-bold my-3">배출가스 측정기</p>
                            <p v-if="$store.state.language=='en'" class="font-weight-bold my-3">Exhaust Gas <br v-if="!$vuetify.breakpoint.mobile "/>Analyzer</p>
                            <v-icon class="d-block" color="grey lighten-3" size="40">mdi-format-quote-close-outline</v-icon>
                        </v-sheet>
                    </div>
                    <div class="d-flex mt-6">
                        <v-sheet
                            width="50%"
                        >
                            <p
                                class="secondary--text font-weight-bold mb-2"
                                :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                            >
                                {{$store.state.language == 'kr'? '사용방법' : 'How to use'}}
                            </p>
                            <p
                                v-if="$store.state.language=='kr'"
                                :class="!$vuetify.breakpoint.mobile? '':'text-caption px-2'"
                            >
                                경유차, 선박, 농기계 등의<br/>
                                매연 측정시 이용
                            </p>
                            <p
                                v-if="$store.state.language=='en'"
                                :class="!$vuetify.breakpoint.mobile? '':'text-caption px-2'"
                            >
                                diesel vehicles, ships, agricultural machinery, etc<br/>
                                Used for smoke measurement
                            </p>

                            <v-divider class="my-4 mx-16"></v-divider>

                            <p
                                class="secondary--text font-weight-bold mb-2"
                                :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                            >
                                {{$store.state.language == 'kr'? '사용처' : 'Where to use'}}
                            </p>
                            <p
                                v-if="$store.state.language=='kr'"
                                :class="!$vuetify.breakpoint.mobile? '':'text-caption px-2'"
                            >
                                관공서, 군부대, 학교, 연구기관<br/>
                                DPF 관련 업체, 배기가스 전문점 등
                            </p>
                            <p
                                v-if="$store.state.language=='en'"
                                :class="!$vuetify.breakpoint.mobile? '':'text-caption px-2'"
                            >
                                Government offices, military units, schools, research institutes<br/>
                                DPF-related companies, exhaust gas stores, etc
                            </p>
                        </v-sheet>
                        <v-sheet
                            width="50%"
                        >
                            <p
                                class="secondary--text font-weight-bold mb-2"
                                :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                            >
                                {{$store.state.language == 'kr'? '사용방법' : 'How to use'}}
                            </p>

                            <p
                                v-if="$store.state.language=='kr'"
                                :class="!$vuetify.breakpoint.mobile? '':'text-caption px-2'"
                            >
                                휘발유차, 가스차, 바이크 등의<br/>
                                배출가스 측정시 이용
                            </p>
                            <p
                                v-if="$store.state.language=='en'"
                                :class="!$vuetify.breakpoint.mobile? '':'text-caption px-2'"
                            >
                                Gasoline, gas, motorcycle, etc. <br/>
                                Used for measuring emissions
                            </p>

                            <v-divider class="my-4 mx-16"></v-divider>

                            <p
                                class="secondary--text font-weight-bold mb-2"
                                :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                            >
                                {{$store.state.language == 'kr'? '사용처' : 'Where to use'}}
                            </p>
                            <p
                                v-if="$store.state.language=='kr'"
                                :class="!$vuetify.breakpoint.mobile? '':'text-caption px-2'"
                            >
                                관공서, 군부대, 학교, 연구기관<br/>
                                DPF 관련 업체, 배기가스 전문점 등
                            </p>
                            <p
                                v-if="$store.state.language=='en'"
                                :class="!$vuetify.breakpoint.mobile? '':'text-caption px-2'"
                            >
                                Government offices, military units, schools, research institutes<br/>
                                DPF-related companies, exhaust gas stores, etc
                            </p>
                        </v-sheet>
                    </div>
                </v-sheet> -->
                <div
                    class="py-4"
                    :class="!$vuetify.breakpoint.mobile? 'd-flex':''"
                >
                    <TipTapReader 
                        v-if="options.content"
                        style="min-height:300px;"
                        class="tiptap_style"
                        :options="options"
                        :key="componentKey"
                    />
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'
import TipTapReader from "@/components/tiptap/Reader"
import { mapState } from 'vuex'

export default {
    components: {
        Banner,
        SideMenu,
        TipTapReader
    },

    data: () => ({
        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        componentKey: 0
    }),

    computed: {
        ...mapState(['language'])
    },

    created() {
        this.$watch('language', this.handleLanguageChange)
    },

    mounted(){
        this.load()
    },

    methods: {
        handleLanguageChange(newLanguage, oldLanguage) {
            this.$http.post("/api/admin/business/select/specific", {
                params: {
                    page: "manufacture",
                    device: this.$vuetify.breakpoint.mobile? 'mobile': 'pc',
                    language: newLanguage
                }
            }).then((res) => {
                this.options.content = res.data[0].content
                this.componentKey++
            })
        },

        load(){
            this.$http.post("/api/admin/business/select/specific", {
                params: {
                    page: "manufacture",
                    device: this.$vuetify.breakpoint.mobile? 'mobile': 'pc',
                    language: this.$store.state.language
                }
            }).then((res) => {
                this.options.content = res.data[0].content
            })
        }
    }
}
</script>