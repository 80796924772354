<template>
    <div>
        <!-- 배너 -->
        <Banner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    {{$store.state.language == 'kr'? '오시는 길' : 'Location'}}
                </p>
                <v-divider></v-divider>

                <v-img
                    class="mt-4"
                    src="@/assets/contents/map.png"
                ></v-img>
                <p
                    v-if="$store.state.language=='kr'"
                    class="mt-3"
                    :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-caption'"
                >
                    <font class="blue--text mr-2">주소 : </font>경기도 시흥시 서울대학로 59-49, 건영테크노밸리 727호<br/>
                    <font class="blue--text mr-2">문의전화 : </font>031-362-5017<br/>
                    <font class="blue--text mr-2">방문 가능시간 : </font>월~금 09:00 ~ 18:00 (12:00 ~ 13:00 점심시간)<br/>
                </p>

                <p
                    v-if="$store.state.language=='en'"
                    class="mt-3"
                    :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-caption'"
                >
                    <font class="blue--text mr-2">Address : </font>727, Kunyoung Techno Vally, 59-49, Seouldaehak-ro, Siheung-si, Gyeonggi-do, Republic of korea<br/>
                    <font class="blue--text mr-2">Tel : </font>+82 31-362-5017<br/>
                    <font class="blue--text mr-2">Available hours : </font>Monday to Friday 09:00 to 18:00 (12:00 to 13:00 lunch time)<br/>
                </p>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'

export default {
    components: {
        Banner,
        SideMenu
    }
}
</script>