<template>
    <v-sheet
        :min-height="!$vuetify.breakpoint.mobile? 540 : 0"
        class="pb-2"
        style="border-bottom:2px solid black;"
    >
        <!-- 제목 -->
        <v-sheet
            class="d-flex"
        >
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 270 : '50%'"
            >
                <p
                    class="font-weight-bold"
                    :class="$vuetify.breakpoint.mobile? 'text-h6' : 'text-h5'"
                    style="cursor:pointer;"
                    :style="tabs == 0? 'color:#000;':'color:#ccc;'"
                    @click="tabs = 0"
                >
                    {{$store.state.language == 'kr'? '인증서':'Cert'}}
                </p>
                <v-sheet
                    height="3"
                    :style="tabs == 0? 'background:#000;':'background:#ccc;'"
                ></v-sheet>
            </v-sheet>
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? '100%' : '50%'"
            >
                <p
                    class="font-weight-bold ml-4"
                    :class="$vuetify.breakpoint.mobile? 'text-h6' : 'text-h5'"
                    style="cursor:pointer;"
                    :style="tabs == 1? 'color:#000;':'color:#ccc;'"
                    @click="tabs = 1"
                >
                    {{$store.state.language == 'kr'? '제품':'Product'}}
                </p>
                <v-sheet
                    height="3"
                    :style="tabs == 1? 'background:#000;':'background:#ccc;'"
                ></v-sheet>
            </v-sheet>
        </v-sheet>

        <!-- 내용 -->
        <v-tabs-items
            v-model="tabs"
            background-color="transparent"
            class="ma-0 pa-0"
        >
            <v-tab-item>
                <v-sheet
                    class="py-4 d-flex"
                    style="cursor:pointer;"
                    @click="link('/introduce/certification')"
                >
                    <v-sheet>
                        <v-img
                            width="110"
                            height="140"
                            src="@/assets/contents/cert_3.jpg"
                        ></v-img>
                    </v-sheet>
                    <v-sheet class="pa-2 ml-5">
                        <p
                            class="font-weight-bold mb-0"
                            :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                        >
                            {{$store.state.language == 'kr'? 'CE 인증서':'CE certification'}}
                        </p>
                    </v-sheet>
                </v-sheet>
                <v-divider></v-divider>
                <v-sheet
                    class="py-4 d-flex"
                    style="cursor:pointer;"
                    @click="link('/introduce/certification')"
                >
                    <v-sheet>
                        <v-img
                            width="110"
                            height="140"
                            src="@/assets/contents/cert_4.jpg"
                        ></v-img>
                    </v-sheet>
                    <v-sheet class="pa-2 ml-5">
                        <p
                            class="font-weight-bold mb-0"
                            :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                        >
                            {{$store.state.language == 'kr'? 'CE 인증서':'CE certification'}}
                        </p>
                    </v-sheet>
                </v-sheet>
                <v-divider></v-divider>
                <v-sheet
                    class="py-4 d-flex"
                    style="cursor:pointer;"
                    @click="link('/introduce/certification')"
                >
                    <v-sheet>
                        <v-img
                            width="110"
                            height="140"
                            src="@/assets/contents/cert_2.jpg"
                        ></v-img>
                    </v-sheet>
                    <v-sheet class="pa-2 ml-5">
                        <p
                            class="font-weight-bold mb-0"
                            :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
                        >
                            {{$store.state.language == 'kr'? '방송통신기자재등의 적합등록 필증':'Registration of Broadcasting and Communtication Equipments'}}
                        </p>
                    </v-sheet>
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <v-sheet
                    class="py-4 d-flex"
                    style="cursor:pointer;"
                    @click="link('/product/light')"
                >
                    <v-sheet>
                        <v-img
                            width="110"
                            height="140"
                            src="@/assets/contents/ctn-2200_image.png"
                        ></v-img>
                    </v-sheet>
                    <v-sheet class="pa-2 ml-5">
                        <p class="text-h6 font-weight-bold mb-0">
                            CTN-2200
                        </p>
                        <p class="text-subtitle-1 secondary--text font-weight-medium mb-0">
                            {{$store.state.language == 'kr'? '광투과식 매연측정기':'Light-transmitting Smoke Meter'}}
                        </p>
                    </v-sheet>
                </v-sheet>
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    data: () => ({
        tabs: 0
    }),

    methods: {
        ...mapMutations(['language_change']),

        link(url){
            this.$router.push(url)
        }
    }
}
</script>