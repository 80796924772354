<template>
    <div>
        <!-- 배너 -->
        <Banner />
        
        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    {{$store.state.language == 'kr'? '인증' : 'Cert'}}
                </p>
                <v-divider></v-divider>

                <v-sheet
                    class="py-10"
                    :class="!$vuetify.breakpoint.mobile? 'd-flex justify-space-between':''"
                >
                    <v-sheet
                        :width="!$vuetify.breakpoint.mobile? '50%':'100%'"
                        :class="!$vuetify.breakpoint.mobile? '':'mb-10'"
                    >
                        <v-img
                            height="400"
                            width="300"
                            class="mx-auto"
                            src="@/assets/contents/cert_3.jpg"
                        ></v-img>
                        <v-sheet class="pt-6 text-center">
                            <p class="text-subtitle-1 font-weight-bold mb-0">
                                {{$store.state.language == 'kr'? 'CE 인증서':'CE certification'}}
                            </p>
                        </v-sheet>
                    </v-sheet>

                    <v-sheet
                        :width="!$vuetify.breakpoint.mobile? '50%':'100%'"
                        :class="!$vuetify.breakpoint.mobile? '':'mb-10'"
                    >
                        <v-img
                            height="400"
                            width="300"
                            class="mx-auto"
                            src="@/assets/contents/cert_4.jpg"
                        ></v-img>
                        <v-sheet class="pt-6 text-center">
                            <p class="text-subtitle-1 font-weight-bold mb-0">
                                {{$store.state.language == 'kr'? 'CE 인증서':'CE certification'}}
                            </p>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
                
                <v-sheet
                    class="py-10"
                    :class="!$vuetify.breakpoint.mobile? 'd-flex justify-space-between':''"
                >
                    <v-sheet
                        :width="!$vuetify.breakpoint.mobile? '50%':'100%'"
                        :class="!$vuetify.breakpoint.mobile? '':'mb-10'"
                    >
                        <v-img
                            height="400"
                            width="300"
                            class="mx-auto"
                            src="@/assets/contents/cert_1.jpg"
                        ></v-img>
                        <v-sheet class="pt-6 text-center">
                            <p class="text-subtitle-1 font-weight-bold mb-0">
                                {{$store.state.language == 'kr'? '측정기기 형식승인서':'Type approval for measuring instrument'}}
                            </p>
                        </v-sheet>
                    </v-sheet>

                    <v-sheet
                        :width="!$vuetify.breakpoint.mobile? '50%':'100%'"
                        :class="!$vuetify.breakpoint.mobile? '':'mb-10'"
                    >
                        <v-img
                            height="400"
                            width="300"
                            class="mx-auto"
                            src="@/assets/contents/cert_2.jpg"
                        ></v-img>
                        <v-sheet class="pt-6 text-center">
                            <p class="text-subtitle-1 font-weight-bold mb-0">
                                {{$store.state.language == 'kr'? '방송통신기자재등의 적합등록 필증':'Registration of Broadcasting and Communtication Equipments'}}
                            </p>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'

export default {
    components: {
        Banner,
        SideMenu
    }
}
</script>