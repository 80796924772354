<template>
    <v-sheet
        :min-height="!$vuetify.breakpoint.mobile? 540 : 0"
        class="pb-2"
        style="border-bottom:2px solid black;"
    >
        <p class="text-h5 font-weight-bold">
            {{$store.state.language == 'kr'? '소개':'Introduce'}}
        </p>
        <v-sheet
            height="3"
            color="black"
        ></v-sheet>
        <v-sheet
            class="d-flex mt-4"
        >
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? '270':'49%'"
                :height="!$vuetify.breakpoint.mobile? '243':''"
                style="cursor:pointer;"
                @click="link('/introduce/greetings')"
            >
                <v-img
                    :src="require('@/assets/contents/home/introduce_'+$store.state.language+'.png')"
                ></v-img>
            </v-sheet>
            <v-spacer></v-spacer>
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? '270':'49%'"
                :height="!$vuetify.breakpoint.mobile? '243':''"
                style="cursor:pointer;"
                @click="link('/product/light')"
            >
                <v-img
                    :src="require('@/assets/contents/home/product_'+$store.state.language+'.png')"
                ></v-img>
            </v-sheet>
        </v-sheet>
        <p
            class="font-weight-bold mb-0 mt-6"
            :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-1'"
        >
            {{$store.state.language == 'kr'? '환경측정기, RF 단말기, 엔진오일 첨가제':'Environmental measuring instruments, RF Terminals, Engine Oil Additive'}}
        </p>
        <p
            style="margin-top: 4px; 
                line-height: 24px;
                font-size: 16px;
                font-weight: 400;
                color: rgb(92, 92, 92);"
        >
            {{$store.state.language == 'kr'? 'CT&G의 모든 제품들은':'All CT&G products are available in'}}<br/>
            {{$store.state.language == 'kr'? '국내 및 해외에서 그 기술력을 인정받고 있습니다.':'Its technology is recognized both at home and abroad.'}}
        </p>
        <v-sheet
            width="180"
            class="text-center py-2 mx-auto"
            :class="$store.state.language == 'en'? 'mt-10 mb-10':'mt-12 mb-16'"
            style="display: block;
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 700;
                    color: rgb(0, 0, 0);
                    border: 2px solid rgb(0, 0, 0);
                    border-radius: 1px;
                    transition: all 200ms ease 0s;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    cursor:pointer;
            "
            @click="link('/business/manufacture')"
        >
            {{$store.state.language == 'kr'? '자세히 보기':'More detail'}}
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    methods: {
        link(url){
            this.$router.push(url)
        }
    }
}
</script>
