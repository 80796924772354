<template>
    <div>
        <!-- Banner -->
        <Banner />

        <!-- 인증서 & 제품, 소개 -->
        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:''"
            :class="!$vuetify.breakpoint.mobile? 'mt-16 mx-auto':'mt-8 px-4'"
        >
            <!-- PC -->
            <v-row
                v-if="!$vuetify.breakpoint.mobile"
                gutter="100"
            >
                <!-- 인증서 & 제품 -->
                <v-col class="px-6">
                    <CertNProduct />
                </v-col>

                <!-- 소개 -->
                <v-col class="px-6">
                    <Introduce />
                </v-col>
            </v-row>

            <!-- Mobile -->
            <v-row
                v-else
            >
                <!-- 소개 -->
                <v-col cols="12" class="mb-10">
                    <Introduce />
                </v-col>

                <!-- 인증서 & 제품 -->
                <v-col cols="12">
                    <CertNProduct />
                </v-col>
            </v-row>
        </v-sheet>

        <!-- 자료실 -->
        <v-sheet
            :width="!$vuetify.breakpoint.mobile? '200':'180'"
            class="mx-auto text-center font-weight-bold py-4 my-16"
            style="display: block; color: rgb(0, 0, 0); border: 2px solid rgb(0, 0, 0); cursor:pointer;"
            :style="!$vuetify.breakpoint.mobile? 'line-height: 24px; font-size: 18px;':'line-height: 20px; font-size: 16px;'"
            @click="link('/documents/manual')"
        >
            {{$store.state.language == 'kr'? '자료실':'Reference'}}
        </v-sheet>
    </div>
</template>
<script>
import Banner from "./home/Banner"
import CertNProduct from "./home/Cert&Product.vue"
import Introduce from "./home/Introduce.vue"

export default {
    components: {
        Banner,
        CertNProduct,
        Introduce
    },

    data: () => ({
        tabs: 0
    }),

    methods: {
        link(url){
            this.$router.push(url)
        }
    }
}
</script>
