<template>
    <div>
        <!-- Header -->
        <Header />

        <!-- Index -->
        <router-view
            :key="$route.fullPath"
        ></router-view>

        <!-- Footer -->
        <Footer />
    </div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
    components: {
        Header,
        Footer
    }
}
</script>