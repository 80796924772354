<template>
    <div>
        <!-- 배너 -->
        <Banner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                v-show="view_type == 'list'"
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    QnA
                </p>
                <v-divider></v-divider>

                <div
                    class="py-4"
                >
                    <!-- 본문 -->
                    <v-sheet class="py-4 rounded-15 customBoard">
                        <!-- 목록 -->
                        <v-data-table
                            v-show="!$vuetify.breakpoint.mobile"
                            hide-default-header
                            hide-default-footer
                            :items="list"
                            no-data-text=""
                        >
                            <template v-slot:header>
                                <tr
                                    :class="$vuetify.breakpoint.mobile? 'text-center':'text-left'"
                                    @click="read(item)"
                                >
                                    <th
                                        class="px-2"
                                        v-if="!$vuetify.breakpoint.mobile" :style="$vuetify.breakpoint.mobile? 'width:80px;':'width:200px'"
                                    >
                                        <p :style="$vuetify.breakpoint.mobile? 'width:80px;':'width:200px'">{{$store.state.language == 'kr'? '카테고리' : 'category'}}</p>
                                    </th>
                                    <th
                                        class="px-2"
                                        v-if="!$vuetify.breakpoint.mobile"
                                        :style="$vuetify.breakpoint.mobile? 'width:100%;':'width:100%'"
                                    >
                                        <p :style="$vuetify.breakpoint.mobile? 'width:100%;':'width:100%'">{{$store.state.language == 'kr'? '제목' : 'title'}}</p>
                                    </th>
                                    <th
                                        v-if="!$vuetify.breakpoint.mobile"
                                        class="px-2"
                                        :style="$vuetify.breakpoint.mobile? 'width:80px;':'width:200px'"
                                    >
                                        <p :style="$vuetify.breakpoint.mobile? 'width:80px;':'width:200px'">{{$store.state.language == 'kr'? '작성일자' : 'date'}}</p>
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr 
                                    class="pa-0 ma-0" 
                                    style="cursor:pointer; height:64px;" 
                                    @click="read(item)"
                                >
                                    <td class="px-2 font-weight-medium" v-if="!$vuetify.breakpoint.mobile">{{item.category}}</td>
                                    <td class="px-2 font-weight-medium">{{item.title}}</td>
                                    <td class="px-2 font-weight-medium" v-if="!$vuetify.breakpoint.mobile">{{new Date().toLocaleDateString()}}</td>
                                </tr>
                            </template>
                        </v-data-table>

                        <!-- 목록 -->
                        <v-data-table
                            v-show="$vuetify.breakpoint.mobile"
                            hide-default-header
                            hide-default-footer
                            :items="list"
                            no-data-text=""
                        >
                            <template v-slot:header>
                                <tr
                                    :class="$vuetify.breakpoint.mobile? 'text-center':'text-left'"
                                    @click="read(item)"
                                >
                                    <th
                                        class="px-2"
                                        :style="$vuetify.breakpoint.mobile? 'width:100%;':'width:100%'"
                                    >
                                        <p :style="$vuetify.breakpoint.mobile? 'width:100%;':'width:100%'">{{$store.state.language == 'kr'? '제목' : 'TITLE'}}</p>
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr 
                                    class="pa-0 ma-0" 
                                    style="cursor:pointer; height:54px; width:100%; border-bottom:1px solid #dedede;"
                                    @click="read(item)"
                                >
                                    <td class="px-3 font-weight-medium" style="border:none; padding:0">{{item.title}}</td>
                                </tr>
                            </template>
                        </v-data-table>
                        <v-divider></v-divider>

                        <!-- 버튼 & 페이지네이션 -->
                        <v-sheet class="mt-3 mx-3 d-flex align-center">
                            <v-sheet
                                width="140"
                                height="40"
                            ></v-sheet>
                            <v-spacer></v-spacer>

                            <!-- 페이징 -->
                            <v-pagination
                                class="my-2"
                                color="primary"
                                v-model="page"
                                :length="pageLength"
                                :total-visible="7"
                            ></v-pagination>
                            <v-spacer></v-spacer>

                            <v-sheet
                                width="140"
                                height="40"
                            ></v-sheet>
                        </v-sheet>

                        <v-sheet class="d-flex justify-center mt-10">
                            <!-- TEXT -->
                            <v-text-field
                                height="40"
                                style="font-size:18px; max-width:340px;"
                                class="rounded-10 font-weight-bold"
                                :class="$vuetify.breakpoint.mobile? '':'ml-8'"
                                outlined
                                hide-details
                                dense
                                persistent-placeholder
                                autofocus
                                v-model="keyword"
                                append-icon="mdi-magnify"
                                @keyup.enter="search()"
                            >
                                <template v-slot:append>
                                    <v-icon size="24" class="mr-1" color="#424361">mdi-magnify</v-icon>
                                </template>
                            </v-text-field>

                            <!-- 검색 버튼 -->
                            <v-btn
                                depressed
                                dark
                                class="ml-3 rounded-10 font-weight-bold"
                                color="primary"
                                height="40"
                                @click="search()"
                            >
                                {{$store.state.language == 'kr'? '검색' : 'search'}}
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </div>
            </v-sheet>

            <v-sheet
                v-show="view_type == 'read'"
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    QnA
                </p>
                <v-divider></v-divider>
                <p
                    style="width:100%; font-size:18px;"
                    class="ma-0 pa-0 pt-6 text-truncate d-flex"
                >
                    <!-- 카테고리 -->
                    <font
                        v-if="category"
                        class="mr-2 primary--text text--lighten-3 font-weight-bold"
                    >
                        [ {{category}} ]
                    </font>

                    <!-- 제목 -->
                    <span class="font-weight-bold">
                        {{title}}
                    </span>

                    <v-spacer class="my-5"></v-spacer>

                    <!-- 작성일 -->
                    <span class="text-end text-body-1 mt-1 grey--text mr-1">
                        {{new Date(created).toLocaleDateString()}}
                    </span>
                </p>
                <v-divider></v-divider>
                <v-sheet class="mt-2 mb-2">
                    <TipTapReader 
                        v-if="options.content"
                        style="min-height:300px;"
                        class="tiptap_style pa-2"
                        :options="options"
                    />
                </v-sheet>
                <v-divider class="my-4"></v-divider>
                <v-sheet
                    class="d-flex justify-end"
                >
                    <v-btn
                        color="primary"
                        class="rounded-10 px-6"
                        depressed
                        large
                        @click="view_type = 'list'"
                    >
                        목록
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'
import TipTapReader from "@/components/tiptap/Reader"

export default {
    components: {
        Banner,
        SideMenu,
        TipTapReader
    },

    data: () => ({
        // 목록
        list: [],

        // 페이징
        page: 1,
        pageLength: 0,
        itemsPerPage: 6,

        // 검색 키워드
        keyword:"",
        
        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        view_type: "list",
        selected_id: "",

        category: "",
        title: "",
        created: ""
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*6,(this.page)*6)
        }
    },

    mounted(){
        this.load()
    },

    methods: {
        load(){
            this.$http.post('/api/admin/qna/list')
            .then((res) => {
                if(res.data.length){
                    this.list = res.data
                    this.currentPageList = res.data.slice(0,6)
                    this.pageLength = Math.ceil(res.data.length/6)
                }
            })
        },

        search(){
            // 카드 검색 결과
            this.$http.post('/api/admin/qna/list/search', {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                if(res.data.length){
                    this.list = res.data
                    this.currentPageList = res.data.slice(0,6)
                    this.pageLength = Math.ceil(res.data.length/6)
                }
            })
        },

        read(item){
            this.selected_id = item.id
            this.view_type = "read"

            if(this.view_type == 'read'){
                this.$http.post("/api/admin/qna/select", {
                    params: {
                        id: this.selected_id
                    }
                }).then((res) => {
                    this.category = res.data[0].category
                    this.title = res.data[0].title
                    this.options.content = res.data[0].content
                    this.created = res.data[0].created
                })
            }
        }
    }
}
</script>