<template>
    <div>
        <!-- 배너 -->
        <Banner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    {{$store.state.language == 'kr'? '광투과식 매연측정기' : 'Light-transmitting Smoke Meter'}}
                </p>
                <v-divider></v-divider>

                <div
                    class="d-flex py-4"
                >
                    <!-- <v-sheet
                        width="100%"
                    >
                        <v-img
                            class="mb-10"
                            src="@/assets/contents/ctn-2200_image.png"
                        ></v-img>
                        <v-img
                            v-show="$store.state.language == 'kr'"
                            class="mb-10"
                            src="@/assets/contents/ctn-2200_table_kr.png"
                        ></v-img>
                        <v-img
                            v-show="$store.state.language == 'en'"
                            class="mb-10"
                            src="@/assets/contents/ctn-2200_table_en.png"
                        ></v-img>

                        <v-sheet
                            v-if="!$vuetify.breakpoint.mobile"
                            class="pa-3 cyan"
                        >
                            <v-sheet
                                height="460"
                                class="pa-6 d-flex justify-space-between rounded-50 rounded-t-0 rounded-bl-0"
                            >
                                <v-sheet style="width:400px;">
                                    <v-sheet
                                        height="5"
                                        :width="!$vuetify.breakpoint.mobile? 252:'100%'"
                                        class="grey mb-4"
                                    ></v-sheet>
                                    <p class="secondary--text text-h5 font-weight-bold mb-2">
                                        {{$store.state.language == 'kr'? '환경측정기기' : 'Environmental measuring instrument'}} CTN-2200
                                    </p>
                                    <p class="secondary--text font-weight-bold" style="font-size:26px;">
                                        {{$store.state.language == 'kr'? '광투과식 매연측정기' : 'Light-transmitting Smoke Meter'}}
                                    </p>
                                    <div class="font-weight-medium grey--text text--darken-1">
                                        <p v-if="$store.state.language == 'kr'">
                                            경유차에서 발생하는 매연농도를<br/>
                                            실시간으로 측정하는 기기로 우리나라 자동차<br/>
                                            매연 안전, 정밀 검사의 채택장비이면서<br/>
                                            환경부의 형식승인을 받아 판매하는 법정 필수<br/>
                                            제품으로 환경개선에 기여하고 있다.
                                        </p>
                                        <p v-if="$store.state.language == 'en'">
                                            The smoke concentration generated by diesel vehicles<br/>
                                            It's a real-time measurement device<br/>
                                            It is an equipment that adopts the safety inspection and precision inspection of smoke<br/>
                                            Statutory requirements for sale with type approval from the Ministry of Environment<br/>
                                            It is contributing to the improvement of the environment with products.
                                        </p>
                                    </div>
                                </v-sheet>

                                <v-sheet
                                    class="align-self-end"
                                    :width="!$vuetify.breakpoint.mobile? 380:'100%'"
                                >
                                    <v-sheet
                                        class="d-flex"
                                    >
                                        <v-icon
                                            size="60"
                                            class="pb-6 mr-5"
                                            color="cyan"
                                        >
                                            mdi-car-turbocharger
                                        </v-icon>
                                        <div>
                                            <v-sheet
                                                height="5"
                                                width="92"
                                                class="grey mb-4"
                                            ></v-sheet>
                                            <p class="secondary--text text-h5 font-weight-bold mb-8">
                                                {{$store.state.language == 'kr'? '제품 특징' : 'Product Features'}}
                                            </p>
                                        </div>
                                    </v-sheet>
                                    <p
                                        class="ml-6 mb-2 font-weight-medium grey--text text--darken-1"
                                        :class="$store.state.language == 'kr'? '':'text-body-2'"
                                    >
                                        {{$store.state.language == 'kr'? '' : ''}}
                                        {{$store.state.language == 'kr'? '빠른 응답속도로 실시간 매연 측정' : 'Real-time smoke measurement with fast response time'}}<br/>

                                        <v-icon class="mr-2" size="20">mdi-minus</v-icon>{{$store.state.language == 'kr'? '빠른 응답속도로 실시간 매연 측정' : 'Real-time smoke measurement with fast response time'}}<br/>
                                        <v-icon class="mr-2" size="20">mdi-minus</v-icon>{{$store.state.language == 'kr'? '차량번호 4자리 인쇄 가능' : 'Can print 4 digits of vehicle number'}}<br/>
                                        <v-icon class="mr-2" size="20">mdi-minus</v-icon>{{$store.state.language == 'kr'? '듀얼팬의 에어커튼 방식으로 렌즈 오염 방지 기능' : 'Dual-fan air curtain to prevent contamination of lenses'}}<br/>
                                        <v-icon class="mr-2" size="20">mdi-minus</v-icon>{{$store.state.language == 'kr'? 'LED 라이트값 자동 조절로 정확한 측정값 산출' : 'Automatically adjust LED light values to calculate accurate measurements'}}<br/>
                                        <v-icon class="mr-2" size="20">mdi-minus</v-icon>{{$store.state.language == 'kr'? 'PC 프로그램 제공으로 노트북 연결 이용 기능' : 'Ability to use laptop connectivity by providing PC programs'}}<br/>
                                        <v-icon class="mr-2" size="20">mdi-minus</v-icon>{{$store.state.language == 'kr'? '스마트하고 가벼워진 무게' : 'Smarter and lighter weight'}}<br/>
                                    </p>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>

                        <v-sheet
                            v-if="$vuetify.breakpoint.mobile"
                            class="pa-3 cyan"
                        >
                            <v-sheet
                                class="pa-4 rounded-50 rounded-t-0 rounded-bl-0"
                            >
                                <v-sheet>
                                    <v-sheet
                                        height="5"
                                        :width="!$vuetify.breakpoint.mobile? 252:'100%'"
                                        class="grey mb-4"
                                    ></v-sheet>
                                    <p class="secondary--text text-h6 font-weight-bold mb-0">
                                        {{$store.state.language == 'kr'? '환경측정기기' : 'Environmental measuring instrument'}} CTN-2200
                                    </p>
                                    <p class="secondary--text font-weight-bold" style="font-size:24px;">
                                        {{$store.state.language == 'kr'? '광투과식 매연측정기' : 'Light-transmitting Smoke Meter'}}
                                    </p>
                                    <p class="font-weight-medium grey--text text--darken-1 text-body-2 mb-8" style="line-height:24px !important;">
                                        <span v-if="$store.state.language == 'kr'">
                                            경유차에서 발생하는 매연농도를
                                            실시간으로 측정하는 기기로 우리나라 자동차
                                            매연 안전, 정밀 검사의 채택장비이면서
                                            환경부의 형식승인을 받아 판매하는 법정 필수
                                            제품으로 환경개선에 기여하고 있다.
                                        </span>
                                        <span v-if="$store.state.language == 'en'">
                                            The smoke concentration generated by diesel vehicles
                                            It's a real-time measurement device
                                            It is an equipment that adopts the safety inspection and precision inspection of smoke
                                            Statutory requirements for sale with type approval from the Ministry of Environment
                                            It is contributing to the improvement of the environment with products.
                                        </span>
                                    </p>
                                </v-sheet>

                                <v-sheet
                                    :width="!$vuetify.breakpoint.mobile? 380:'100%'"
                                >
                                    <v-sheet
                                        class="d-flex"
                                    >
                                        <v-icon
                                            size="52"
                                            class="pb-4 mr-5"
                                            color="cyan"
                                        >
                                            mdi-car-turbocharger
                                        </v-icon>
                                        <div>
                                            <v-sheet
                                                height="5"
                                                width="92"
                                                class="grey mb-2"
                                            ></v-sheet>
                                            <p class="secondary--text text-h6 font-weight-bold">
                                                {{$store.state.language == 'kr'? '제품 특징' : 'Product Features'}}
                                            </p>
                                        </div>
                                    </v-sheet>
                                    <v-list class="pa-0" dense>
                                        <v-list-item class="pa-0 font-weight-medium grey--text text--darken-1 text-body-2">
                                            <v-icon class="mr-1" size="12">mdi-minus</v-icon>
                                            {{$store.state.language == 'kr'? '빠른 응답속도로 실시간 매연 측정' : 'Real-time smoke measurement with fast response time'}}
                                        </v-list-item>
                                        <v-list-item class="pa-0 font-weight-medium grey--text text--darken-1 text-body-2">
                                            <v-icon class="mr-1" size="12">mdi-minus</v-icon>
                                            {{$store.state.language == 'kr'? '차량번호 4자리 인쇄 가능' : 'Can print 4 digits of vehicle number'}}
                                        </v-list-item>
                                        <v-list-item class="pa-0 font-weight-medium grey--text text--darken-1 text-body-2">
                                            <v-icon class="mr-1" size="12">mdi-minus</v-icon>
                                            {{$store.state.language == 'kr'? '듀얼팬의 에어커튼 방식으로 렌즈 오염 방지 기능' : 'Dual-fan air curtain to prevent contamination of lenses'}}
                                        </v-list-item>
                                        <v-list-item class="pa-0 font-weight-medium grey--text text--darken-1 text-body-2">
                                            <v-icon class="mr-1" size="12">mdi-minus</v-icon>
                                            {{$store.state.language == 'kr'? 'LED 라이트값 자동 조절로 정확한 측정값 산출' : 'Automatically adjust LED light values to calculate accurate measurements'}}
                                        </v-list-item>
                                        <v-list-item class="pa-0 font-weight-medium grey--text text--darken-1 text-body-2">
                                            <v-icon class="mr-1" size="12">mdi-minus</v-icon>
                                            {{$store.state.language == 'kr'? 'PC 프로그램 제공으로 노트북 연결 이용 기능' : 'Ability to use laptop connectivity by providing PC programs'}}
                                        </v-list-item>
                                        <v-list-item class="pa-0 font-weight-medium grey--text text--darken-1 text-body-2">
                                            <v-icon class="mr-1" size="12">mdi-minus</v-icon>
                                            {{$store.state.language == 'kr'? '스마트하고 가벼워진 무게' : 'Smarter and lighter weight'}}
                                        </v-list-item>
                                    </v-list>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>
                    </v-sheet> -->
                    
                    <TipTapReader 
                        v-if="options.content"
                        style="min-height:300px;"
                        class="tiptap_style"
                        :options="options"
                        :key="componentKey"
                    />
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'
import TipTapReader from "@/components/tiptap/Reader"
import { mapState } from 'vuex'

export default {
    components: {
        Banner,
        SideMenu,
        TipTapReader
    },

    data: () => ({
        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        componentKey: 0
    }),

    computed: {
        ...mapState(['language'])
    },

    created() {
        this.$watch('language', this.handleLanguageChange)
    },

    mounted(){
        this.load()
    },

    methods: {
        handleLanguageChange(newLanguage, oldLanguage) {
            this.$http.post("/api/admin/product/select/specific", {
                params: {
                    page: "light",
                    device: this.$vuetify.breakpoint.mobile? 'mobile': 'pc',
                    language: newLanguage
                }
            }).then((res) => {
                this.options.content = res.data[0].content
                this.componentKey++
            })
        },

        load(){
            this.$http.post("/api/admin/product/select/specific", {
                params: {
                    page: "light",
                    device: this.$vuetify.breakpoint.mobile? 'mobile': 'pc',
                    language: this.$store.state.language
                }
            }).then((res) => {
                this.options.content = res.data[0].content
            })
        }
    }
}
</script>