<template>
    <div>
        <!-- 배너 -->
        <Banner />
        
        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-16':'pa-4 pb-4'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    {{$store.state.language == 'kr'? '인사말' : 'Greetings'}}
                </p>
                <v-divider></v-divider>
                <v-sheet
                    :class="!$vuetify.breakpoint.mobile? 'greetings pt-12':'pt-6'"
                    :height="!$vuetify.breakpoint.mobile? 600:''"
                >
                    <v-sheet
                        :width="!$vuetify.breakpoint.mobile? 540:'100%'"
                        color="transparent"
                    >
                        <p
                            class="mb-8 text-h4 font-weight-bold secondary--text"
                            :class="!$vuetify.breakpoint.mobile? 'text-h4':'text-h6'"
                        >
                            Create Clean Technology
                        </p>

                        <p
                            v-if="$store.state.language=='kr'"
                            :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1 mb-12':'text-body-2 mb-6'"
                        >
                            청정기술을 위한 솔루션 확장<br/>
                            지구환경을 지키기 위해 기여하는 자동차 환경측정기기 전문기업<br/>
                            <br/>
                            현대사회의 기업의 가치는 큰 기업이 아닙니다.<br/>
                            비록 작지만 기술력과 끊임없이 연구하고 개발하여 그 가치를 스스로 만들어내는 기업만이 생존할수 있습니다.<br/>
                            <br/>
                            씨티엔지는 자동차 배출가스측정기, 디젤매연측정기 등의 환경측정기기 제조를<br/>
                            주축으로 다양한 센서를 이용하여 분석 및 측정시스템을 개발 제조하는 회사로<br/>
                            자동차 매연 및 배기가스 측정하는 장비를 제조판매함으로써<br/>
                            세계적으로 대두되고 있는 기후 변화에 대기환경을 감시하고<br/>
                            관리할수 있는데 기여하고 있습니다.<br/>
                            <br/>
                            자동차 대기관련 기반 제품을 연구 개발하여 더 건강하고<br/>
                            쾌적한 환경을 누릴 수 있도록 최선을 다할 것이며,<br/>
                            앞으로도 세계기후에 기여할수 있는 끊임없는 연구개발을 통하여<br/>
                            새로운 기술과 서비스를 선보일 것을 약속드립니다.<br/>
                            <br/>
                            아울러 깨끗한 기업문화와 투명한 회사경영을 실천할 수 있도록<br/>
                            끊임없이 노력하겠습니다.
                        </p>

                        <p
                            v-if="$store.state.language=='en'"
                            class="mb-12"
                            :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-body-2'"
                        >
                            Extending solutions for clean technology<br/>
                            A company specializing in automotive environmental measuring devices that contribute to protecting the global environment
                            <br/>
                            <br/>

                            The value of a company in modern society is not a big company.<br/>
                            Although small, only companies that constantly research and develop technology and create their own value can survive.
                            <br/>
                            <br/>

                            CitiNG is mainly manufacturing environmental measuring devices such as automobile emission measuring devices and diesel smoke measuring devices
                            It is a company that develops and manufactures analysis and measurement systems using various sensors<br/>
                            By manufacturing and selling equipment<br/>
                            that measures automobile fumes and emissions<br/>
                            It contributes to monitoring and managing<br/>
                            the atmosphere in the global climate change.<br/>
                            <br/>

                            Research and development of automotive atmosphere-related base products to make them healthier<br/>
                            We will do our best to enjoy a pleasant environment,<br/>
                            We promise to introduce new technologies and services through continuous research and development that can contribute to the global climate in the future.
                            <br/>
                            <br/>
                            In addition, we will continue to make efforts to practice clean corporate culture and transparent company management.
                        </p>

                        <p
                            v-if="$store.state.language=='kr'"
                            class="mt-6 mb-0 d-block"
                            :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-2'"
                        >
                            주식회사 씨티엔지 임직원 일동
                        </p>

                        <p
                            v-if="$store.state.language=='en'"
                            class="mt-6 mb-0 d-block"
                            :class="!$vuetify.breakpoint.mobile? 'text-h6':'text-subtitle-2'"
                        >
                            All executives and employees of CT&G Co., Ltd
                        </p>
                    </v-sheet>
                </v-sheet>
                <v-sheet v-if="!$vuetify.breakpoint.mobile" height="160"></v-sheet>
            </v-sheet>
            <v-img
                v-if="$vuetify.breakpoint.mobile"
                src="@/assets/contents/greetings_mobile.png"
            />
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'

export default {
    components: {
        Banner,
        SideMenu
    }
}
</script>
<style scoped>
.greetings {
    height:700px !important;
    background: url('~@/assets/contents/greetings_pc.png');
    background-repeat: no-repeat;
    background-position: bottom;
}
</style>