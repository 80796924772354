<template>
    <div>
        <!-- 배너 -->
        <Banner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    {{$store.state.language == 'kr'? '카탈로그' : 'Catalog'}}
                </p>
                <v-divider></v-divider>

                <!-- <div
                    class="d-flex justify-space-between py-4 text-center"
                >
                    <v-sheet
                        width="49%"
                        :class="!$vuetify.breakpoint.mobile? 'pa-10':'py-8 px-4'"
                        style="cursor:pointer;"
                        outlined
                        @click="download('CTN-2200 브로슈어_한글.pdf')"
                    >
                        <v-img
                            :width="!$vuetify.breakpoint.mobile? 100:80"
                            class="mb-4 mx-auto"
                            src="@/assets/contents/pdf.png"
                        ></v-img>
                        <p class="primary--text text-body-2">
                            CTN-2200 {{$store.state.language == 'kr'? '브로슈어_한글' : 'Brochure_kr'}}.pdf
                        </p>
                        <p class="text-caption red--text">
                            {{$store.state.language == 'kr'? '클릭하여 다운로드' : 'Click To Download'}}
                        </p>
                    </v-sheet>
                    <v-sheet
                        width="49%"
                        :class="!$vuetify.breakpoint.mobile? 'pa-10':'py-8 px-4'"
                        style="cursor:pointer;"
                        outlined
                        @click="download('CTN-2200 브로슈어_영문.pdf')"
                    >
                        <v-img
                            :width="!$vuetify.breakpoint.mobile? 100:80"
                            class="mb-4 mx-auto"
                            src="@/assets/contents/pdf.png"
                        ></v-img>
                        <p class="primary--text text-body-2">
                            CTN-2200 {{$store.state.language == 'kr'? '브로슈어_영문' : 'Brochure_en'}}.pdf
                        </p>
                        <p class="text-caption red--text">
                            {{$store.state.language == 'kr'? '클릭하여 다운로드' : 'Click To Download'}}
                        </p>
                    </v-sheet>
                </div> -->
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'

export default {
    components: {
        Banner,
        SideMenu
    },

    methods: {
        download(file) {
            this.$http.post("/api/file/download", {
                params: {
                    file: file
                }
            },
            {
                responseType: 'blob' // 응답 타입을 'blob'으로 설정
            }).then((res) => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data])) // 블롭 객체 생성
                const link = document.createElement('a') // 링크 엘리먼트 생성
                link.href = url // 링크에 다운로드 URL 설정
                link.setAttribute('download', file) // 파일 이름 설정
                document.body.appendChild(link) // 링크를 body에 추가
                link.click() // 클릭 이벤트 발생
                document.body.removeChild(link) // 링크 제거
                window.URL.revokeObjectURL(url) // 사용한 URL 해제
            })
        }
    }
}
</script>