<template>
    <v-sheet
        style="border-bottom:1px solid #eee;"
    >
        <v-sheet
            height="64"
            class="px-3 d-flex justify-space-between align-center"
        >
            <!-- 언어선택 -->
            <v-btn
                icon
                @click="drawer = !drawer"
            >
                <v-icon>mdi-menu</v-icon>
            </v-btn>

            <!-- 로고 -->
            <v-sheet 
                width="72"
                style="cursor:pointer;"
                @click="link('/')"
            >
                <v-img
                    contain
                    src="@/assets/logo/default.svg"
                ></v-img>
            </v-sheet>

            <!-- 언어선택 -->
            <v-btn
                icon
                @click="language_change()"
            >
                <span v-if="$store.state.language=='kr'">한</span>
                <span v-if="$store.state.language=='en'">EN</span>
            </v-btn>
        </v-sheet>

        <!-- 사이드바 -->
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
            <v-list
                nav
                class="font-weight-medium px-2"
            >
                <v-list-item
                    v-for="(item, index) in menu" :key="index"
                    :to="item.url"
                    class="px-4"
                    active-class="secondary white--text"
                >
                    {{$store.state.language=='kr'? item.title_kr : item.title_en}}
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-sheet>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    data: () => ({
        drawer: false,

        menu: [
            { title_kr: "회사소개", title_en: "Introduce", url: "/introduce/greetings" },
            { title_kr: "사업소개", title_en: "Business", url: "/business/manufacture" },
            { title_kr: "제품소개", title_en: "Product", url: "/product/light" },
            { title_kr: "자료실",  title_en: "Documents", url: "/documents/manual" },
            { title_kr: "고객센터", title_en: "Service", url: "/service/qna" },
        ]
    }),

    methods: {
        ...mapMutations(['language_change']),

        link(url){
            this.$router.push(url)
        }
    }
}
</script>