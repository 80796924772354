<template>
    <div>
        <!-- 배너 -->
        <Banner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1000:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 mx-auto':'pt-4 pb-6'"
        >
            <p
                class="text-h5 text-center font-weight-medium"
            >
                {{$store.state.language == 'kr'? '사업제휴':'Partner'}}
            </p>
            <v-divider class="mb-10"></v-divider>
            <v-sheet
                :class="!$vuetify.breakpoint.mobile? 'px-10':'px-4'"
            >
                <v-text-field
                    outlined
                    class="rounded-15"
                    v-model="title"
                    :placeholder="$store.state.language == 'kr'? '사업제휴 제목':'Partner Title'"
                ></v-text-field>
                <v-textarea
                    outlined
                    rows="16"
                    class="rounded-15"
                    v-model="content"
                    :placeholder="$store.state.language == 'kr'? '사업제휴 내용':'Partner Content'"
                ></v-textarea>
            </v-sheet>

            <v-sheet
                class="d-flex justify-center"
            >
                <v-btn
                    class="rounded-10 px-10"
                    large
                    depressed
                    color="primary lighten-1"
                    @click="submit()"
                >
                    {{$store.state.language == 'kr'? '발송하기':'SEND'}}
                </v-btn>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './partner/components/Banner'

export default {
    components: {
        Banner
    },

    data: () => ({
        title: "",
        content: ""
    }),

    methods: {
        // 발송하기
        submit(){
            if(this.title == "")
            {
                if(this.$store.state.language == 'kr')
                {
                    alert("제목을 입력해주세요")
                }
                else
                {
                    alert("Please enter the title")
                }
            }else if(this.content == "")
            {
                if(this.$store.state.language == 'kr')
                {
                    alert("내용을 입력해주세요")
                }
                else
                {
                    alert("Please enter the content")
                }
            }else{
                this.$http.post("/api/partner/send", {
                    params: {
                        title: this.title,
                        content: this.content
                    }
                }).then((res) => {
                    if(this.$store.state.language == 'kr')
                    {
                        alert("발송되었습니다")
                        this.$router.push("/")
                    }
                    else
                    {
                        alert("Sent Complete")
                        this.$router.push("/")
                    }
                })
            }
        }
    }
}
</script>