<template>
    <div>
        <v-sheet
            height="100"
            width="1200"
            class="d-flex align-center pr-6 mx-auto"
            color="transparent"
        >
            <!-- 로고 -->
            <v-sheet 
                width="120"
                class="ml-4 mr-16"
                style="cursor:pointer;"
                @click="link('/')"
            >
                <v-img
                    height="60"
                    contain
                    src="@/assets/logo/default.svg"
                ></v-img>
            </v-sheet>

            <!-- 메뉴 -->
            <div>
                <v-btn
                    v-ripple="false"
                    class="text-subtitle-1 font-weight-medium px-3 mx-3"
                    text
                    large
                    active-class="secondary--text"
                    exact
                    to="/introduce/greetings"
                >
                    {{$store.state.language == 'kr'? '회사소개':'introduce'}}
                </v-btn>
                <v-btn
                    v-ripple="false"
                    class="text-subtitle-1 font-weight-medium px-3 mx-3"
                    text
                    large
                    active-class="secondary--text"
                    exact
                    to="/business/manufacture"
                >
                    {{$store.state.language == 'kr'? '사업소개':'business'}}
                </v-btn>
                <v-btn
                    v-ripple="false"
                    class="text-subtitle-1 font-weight-medium px-3 mx-3"
                    text
                    large
                    active-class="secondary--text"
                    exact
                    to="/product/light"
                >
                    {{$store.state.language == 'kr'? '제품소개':'product'}}
                </v-btn>
                <v-btn
                    v-ripple="false"
                    class="text-subtitle-1 font-weight-medium px-3 mx-3"
                    text
                    large
                    active-class="secondary--text"
                    exact
                    to="/documents/manual"
                >
                    {{$store.state.language == 'kr'? '자료실':'documents'}}
                </v-btn>
                <v-btn
                    v-ripple="false"
                    class="text-subtitle-1 font-weight-medium px-3 mx-3"
                    text
                    large
                    active-class="secondary--text"
                    exact
                    to="/service/qna"
                >
                    {{$store.state.language == 'kr'? '고객센터':'service'}}
                </v-btn>
            </div>
            <v-spacer></v-spacer>

            <!-- 언어선택 -->
            <v-btn
                text
                v-ripple="false"
                :class="$store.state.language == 'kr'? '':'grey--text text--lighten-1'"
                @click="language_change()"
            >
                한국어
            </v-btn>
            <v-btn
                class="mb-2px"
                text
                x-small
            >
                |
            </v-btn>
            <v-btn
                text
                v-ripple="false"
                :class="$store.state.language == 'en'? '':'grey--text text--lighten-1'"
                @click="language_change()"
            >
                ENGLISH
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    methods: {
        ...mapMutations(['language_change']),

        link(url){
            this.$router.push(url)
        }
    }
}
</script>
<style scoped>
.v-btn::before {
  background-color: transparent;
}

.v-btn:hover {
    color:#2d76a7 !important;
}
</style>