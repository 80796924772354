<template>
    <div>
        <!-- 배너 -->
        <Banner />

        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-10 pb-16 d-flex justify-space-between mx-auto':''"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 940:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    {{$store.state.language == 'kr'? 'R F 단말기' : 'RF Terminal'}}
                </p>
                <v-divider></v-divider>

                <div
                    class="py-4"
                    :class="!$vuetify.breakpoint.mobile? 'd-flex':''"
                >
                    <!-- <v-sheet v-if="$vuetify.breakpoint.mobile">
                        <v-img
                            class="mb-4"
                            src="@/assets/contents/rf.png"
                        ></v-img>
                    </v-sheet>
                    <v-sheet
                        class="pr-6"
                    >
                        <p
                            class="text-h6 font-weight-medium"
                        >
                            RFID(Radio-Frequency Identification)<span v-if="$store.state.language=='kr'">이란?</span>
                        </p>

                        <p
                            v-if="$store.state.language=='kr'"
                            class="mb-10"
                        >
                            RFID 기술이란 전파를 이용해 먼 거리에서 정보를 인식하는<br/>
                            기술을 말하며, 전자기 유도 방식으로 통신한다.
                            여기에는 RFID 태그(이하 태그)와, RFID 판독기(이하 판도기)가 필요하다,
                            태그는 안테나와 집적 회로로 이루어지는데,
                            직접 회로 안에 정보를 기록하고 안테나를 통해 판독기에게 정보를 송신하다.
                            이 정보는 태그가 부착된 대상을 식별하는 데
                            이용된다. 쉽게 말해, 바코드와 유사한 기능을 하는 것이다.
                            RFID가 바코드 시스템과 다른 점은 빛을 이용해 판독하는 대신
                            전파를 이용한다는 것이다. 따라서 바코드 판독기처럼 짧은 거리에서만작동하지 않고 먼 거리에서도 태그를 읽을 수 있으며,
                            심지어 사이에 있는 물체를 통과해서 정보를 수신할 수도 있다.
                        </p>

                        <p
                            v-if="$store.state.language=='en'"
                            class="mb-10"
                        >
                            RFID technology uses radio waves to recognize information from a long distance
                            It refers to technology and communicates in an electromagnetic induction manner.
                            This requires an RFID tag (hereinafter referred to as a tag) and an RFID reader
                            (hereinafter referred to as a game console), which consists of an antenna and an integrated circuit,
                            recording information directly in the circuit and transmitting information to the reader through the antenna.
                            This information is used to identify the tagged target. Simply put, it functions similar to a barcode.
                            What makes RFID different from the barcode system is that it uses radio waves instead of reading using light.
                            Therefore, tags can be read at long distances without operating at short distances like barcode readers,
                            and even receive information through objects in between.
                        </p>

                        <p class="text-h6 font-weight-medium secondary--text mb-2">
                            {{$store.state.language == 'kr'? '활용' : 'application'}}
                        </p>
                        <p style="line-height:28px;">
                            {{$store.state.language == 'kr'? 'RF 비접촉식 스마트카드' : 'RF Contactless Smart Card'}}<br/>
                            {{$store.state.language == 'kr'? '무선홈 보안시스템' : 'Wireless Home Security System'}}<br/>
                            {{$store.state.language == 'kr'? '무선화재 방지시스템' : 'Wireless Fire Prevention System'}}<br/>
                            {{$store.state.language == 'kr'? 'RF 무선 제어와 과련된 기타 많은 응용 분야' : 'RF Radio Control and many other applications'}}
                        </p>
                    </v-sheet>
                    <v-sheet v-if="!$vuetify.breakpoint.mobile">
                        <v-img
                            src="@/assets/contents/rf.png"
                        ></v-img>
                    </v-sheet> -->

                    <TipTapReader 
                        v-if="options.content"
                        style="min-height:300px;"
                        class="tiptap_style"
                        :options="options"
                        :key="componentKey"
                    />
                </div>
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Banner from './components/Banner'
import SideMenu from './components/SideMenu'
import TipTapReader from "@/components/tiptap/Reader"
import { mapState } from 'vuex'

export default {
    components: {
        Banner,
        SideMenu,
        TipTapReader
    },

    data: () => ({
        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        componentKey: 0
    }),

    computed: {
        ...mapState(['language'])
    },

    created() {
        this.$watch('language', this.handleLanguageChange)
    },

    mounted(){
        this.load()
    },

    methods: {
        handleLanguageChange(newLanguage, oldLanguage) {
            this.$http.post("/api/admin/business/select/specific", {
                params: {
                    page: "rf",
                    device: this.$vuetify.breakpoint.mobile? 'mobile': 'pc',
                    language: newLanguage
                }
            }).then((res) => {
                this.options.content = res.data[0].content
                this.componentKey++
            })
        },

        load(){
            this.$http.post("/api/admin/business/select/specific", {
                params: {
                    page: "rf",
                    device: this.$vuetify.breakpoint.mobile? 'mobile': 'pc',
                    language: this.$store.state.language
                }
            }).then((res) => {
                this.options.content = res.data[0].content
            })
        }
    }
}
</script>